export default function showOrderFormFields() {
    //Show more select fields in bestilling form Isbilen
    const btn = document.getElementById("fields-btn-more");
    if (window.location.pathname === '/bestillingsskjema') {
        btn.addEventListener('click', event => {
            const fields = document.getElementsByClassName("hidden-field");
            const array = Array.from(fields);
            array.map((field) => field.setAttribute("style", "display:inline-block;"));
            btn.setAttribute("style", "display:none;");
        });
    }
   
}