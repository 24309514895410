export default function displayBackgroundIllustration() {
  // Display background illustration based on length  of element

  var element = document.getElementById("bgIllu");
  if (element != null) {
    if (element.clientHeight > 8600) {
      element.classList.add("c-bgIllu-6");
    } else if (element.clientHeight > 7300) {
      element.classList.add("c-bgIllu-5");
    } else if (element.clientHeight > 5600) {
      element.classList.add("c-bgIllu-4");
    } else if (element.clientHeight > 4000) {
      element.classList.add("c-bgIllu-3");
    } else if (element.clientHeight > 2400) {
      element.classList.add("c-bgIllu-2");
    } else if (element.clientHeight > 800) {
      element.classList.add("c-bgIllu-1");
    }
  }
}
