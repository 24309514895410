export default function styleFileUpload() {

  function updateImageDisplay() {
    while (preview.firstChild) {
      preview.removeChild(preview.firstChild);
    }

    var curFiles = input.files;
    if (curFiles.length === 0) {
      var para = document.createElement('p');
      para.textContent = 'Ingen filer er valgt for opplasting';
      preview.appendChild(para);
    } else {
      var list = document.createElement('div');
      preview.appendChild(list);

      var file = curFiles[0];
      var listItem = document.createElement('div');
      var para = document.createElement('p');
      if (validFileType(file)) {
        para.textContent = 'Filnavn: ' + file.name + ', Filstørrelse: ' + returnFileSize(file.size) + '.';
        var image = document.createElement('img');
        image.src = URL.createObjectURL(file);

        listItem.appendChild(image);
        listItem.appendChild(para);
      } else {
        para.textContent = 'Filen ' + file.name + ' har ikke en gyldig bildefiltype. Prøv igjen med en annen filtype.';
        listItem.appendChild(para);
      }

      list.appendChild(listItem);
    }
  }

  const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon"
  ];

  function validFileType(file) {
    return fileTypes.indexOf(file.type) != -1;
  }

  function returnFileSize(number) {
    if (number < 1024) {
      return number + 'bytes';
    } else if (number >= 1024 && number < 1048576) {
      return (number/1024).toFixed(1) + 'KB';
    } else if (number >= 1048576) {
      return (number/1048576).toFixed(1) + 'MB';
    }
  }

  var fileInputId = "fields-imageAttachment";

  var input = document.getElementById(fileInputId);

  if (!input) {
    return;
  }

  var root = input.parentElement;

  if (!root) {
    return;
  }

  var preview = document.createElement("div");

  root.appendChild(preview);

  input.addEventListener('change', updateImageDisplay);


  var newLabel = document.createElement("label");
  newLabel.innerHTML = "Last opp bilde";
  newLabel.setAttribute("for", fileInputId);
  newLabel.className = "sprout-form-upload-button";
  input.className = "sprout-form-upload-hidden";

  root.appendChild(newLabel);

}
