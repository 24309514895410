export default function showHomeDeliveryBasedOnDepartment() {

  var department = document.getElementById("fields-department");

  if (!department) {
    return;
  }

  if (window.location.pathname !== '/bestillingsskjema') {
    return;
  }

  department.addEventListener('change', function(event) {
    var selectedDepartment = event.target.options[event.target.selectedIndex].text;
    var homeDeliveryCheckbox = document.getElementsByClassName('order-form-checkbox')[0];

    if (!homeDeliveryCheckbox) {
      return;
    }

    // Hide home delivery checkbox if user selects something other than Oslo
    if (selectedDepartment != 'Oslo') {
      homeDeliveryCheckbox.setAttribute("style", "display: none;");
      return;
    }

    // Show home delivery checkbox if user selects Oslo
    homeDeliveryCheckbox.setAttribute("style", "display: block;");
  });

  // If user has pressed back button, the form is refilled after some time.
  // If Oslo is in the refilled department, we have to unhide the checkbox again
  setTimeout(function () {

    var text = department.options[department.selectedIndex].text;

    if (text == "Oslo") {
      var homeDeliveryCheckbox = document.getElementsByClassName('order-form-checkbox')[0];

      if (!homeDeliveryCheckbox) {
        return;
      }

      homeDeliveryCheckbox.setAttribute("style", "display: block;");
    }
  }, 500);

}
