import displayBackgroundIllustration from "./displayBackgroundIllustration";
import showOrderFormFields from "./showOrderFormFields";
import autoCompletePostalCode from "./autoCompletePostalCode";
import styleFileUpload from "./styleFileUpload";
import showHomeDeliveryBasedOnDepartment from "./showHomeDeliveryBasedOnDepartment";

displayBackgroundIllustration();
showOrderFormFields();
autoCompletePostalCode();
styleFileUpload();
showHomeDeliveryBasedOnDepartment();
