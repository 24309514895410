var postalCodes = require('norway-postal-codes');

export default function autoCompletePostalCode() {

  var postalCodeElement = document.getElementById('fields-postalCode');

  if (postalCodeElement) {
    postalCodeElement.addEventListener('change', function () {
      var streetCity = document.getElementById('fields-postalPlace');
      if (postalCodes.hasOwnProperty(postalCodeElement.value.toString())) {
        var postalCode = postalCodeElement.value;
        streetCity.value = postalCodes[postalCode];
      }
    });
  }
}
